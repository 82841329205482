import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Products = () => {
  const productsData = useStaticQuery(graphql`
  {
    allContentfulProducts {
      nodes {
        productCards {
            heading
            subHeading
            description
            icon {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
          heading
          subheading
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `);

  const productsInThePipelineData = productsData.allContentfulProducts.nodes;

  return (
    <div>
      {productsInThePipelineData.map(({
        heading,
        subheading,
        description,
        productCards,
      }) => (
        <section key={heading} className="lg:flex flex-wrap py-28 relative bg-background w-full">
          <div className="md:container xl:px-[3.3rem] mx-auto px-4 pt-4 sm:px-3">
            <div className="flex flex-wrap align-items-center product-wrapper">
              <div className="lg:w-5/12 md:w-full" data-wow-offset="50" data-wow-delay=".5s">
                <div className="flex-wrap w-full md:flex-none">
                  <div className="font-futura uppercase text-primary block text-md tracking-1 font-light mb-3 w-full">{heading}</div>
                  <h2 className="md:text-36px md:leading-1.3 font-futura block text-xl font-bold mb-md w-full">{subheading}</h2>
                  <div
                    className="font-futura productContent md:pr-0 font-light md:mr-10 lg:mr-7 sm:mr-0 prose"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: description.childMarkdownRemark.html,
                    }}
                  />
                  <a className="font-futura  no-underline bg-primary rounded-full shadow-lg text-secondary inline-block text-md h-4 font-bold leading-lg mt-5 px-wide transition-all ease-in-out delay-75 hover:opacity-90 " target="_blank" rel="noreferrer" aria-label="action button" href="https://vitamin2.typeform.com/to/zNyk8z">GET STARTED</a>
                </div>
              </div>

              <div className="lg:w-7/12 md:w-full wow" data-wow-offset="50" data-wow-delay=".5s">
                <div className="md:flex block md:gap-x-10 lg:gap-x-10 inner monthly">
                  {productCards.map((productCardData) => (
                    <div key={productCardData.heading} className="mt-5 md:mt-[0] flex-col lg:w-1/2 md:w-12 md:pr-12px md:contents">
                      <div className="flex-auto p-[6%] ssm:w-full md:py-5 md:px-4 shadow-md block relative text-center w-screen/6 md:mx-md mx-[0]">
                        <figure className="items-center justify-center flex my-5 w-full">
                          <GatsbyImage
                            alt={productCardData.subHeading}
                            image={productCardData.icon.gatsbyImageData}
                            className="h-6 w-6 ssm:h-55px ssm:w-55px"
                          />
                        </figure>
                        <div className="font-futura uppercase block mb-md w-full text-md tracking-2 text-opacity-60">{productCardData.heading}</div>
                        <b className="md:text-26px text-[30px] l:text-26px font-futura block lg:text-2xl w-full leading-1">{productCardData.subHeading}</b>
                        <p className="font-futura mt-5 mb-3 py-wide l:text-14px l:py-md-1 border-t border-border border-solid prose-">
                          {productCardData.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

      ))}

      <section className="text-secondary py-28 bg-secondary w-full bg-cover relative bg-default-pattern bg-fixed before:border-solid before:content before:h-0 before:left-20vw before:top-20px before:absolute before:w-0 before:z-0 before:border-t-transparent before:border-r-bold before:border-b-transparent before:border-l-transparent before:border-t-20px before:border-r-120vw bofore:border-b-20px before:border-l-0 after:bg-overlay after:content after:h-full after:left-0 after:absolute after:w-full after:z-0 after:top-0 after:mix-blend-lighten">
        <div className="xl:px-[3.3rem]  md:py-12px md:px-12px ssm:py-12px ssm:px-12px md:container relative z-2 cta-section wow mx-0 md:mx-auto pt-4 sm:px-3" data-wow-offset="50" data-wow-delay=".5s">
          <div className="font-futura block text-base font-normal tracking-1 mb-3 w-full">#WatooTijay</div>
          <h2 className="md:w-full font-futura md:text-[3.5rem] md:leading-1.3 font-semibold block mb-md w-full text-[2.1rem] leading-normal">
            You have ideas. We have solutions.
            <br />
            Time to join forces.
          </h2>
          <a className="font-futura no-underline bg-secondary text-primary inline-block text-md mt-5 px-wide font-bold leading-lg rounded-full shadow-lg h-4 transition-all ease-in-out delay-100 hover:opacity-90" target="_blank" rel="noreferrer" href="https://vitamin2.typeform.com/to/zNyk8z">
            LET&apos;S GET TO WORK
          </a>
        </div>
      </section>
    </div>
  );
};

export default Products;
